import { forwardRef } from 'react'
import { Input } from '@atlasmic/shared/components/inputs/Input'

const UPSCALE_RATIO = 16 / 14

export const MobileFriendlyInput = forwardRef(function MobileFriendlyInput(props, ref) {
  return (
    <Input
      ref={ref}
      fontSize={1.75 * UPSCALE_RATIO}
      transform={`scale(${1 / UPSCALE_RATIO})`}
      width={`${100 * UPSCALE_RATIO}%`}
      marginLeft={`${(-100 * UPSCALE_RATIO + 100) / 2}%`}
      paddingX={1 * UPSCALE_RATIO}
      paddingY={1 * UPSCALE_RATIO}
      {...props}
    />
  )
})
