import { useLogin as useSharedLogin } from '@atlasmic/shared/hooks/auth/useLogin'
import { useUpdateWebSockets } from '@atlasmic/shared/hooks/webSockets/useUpdateWebSockets'
import { useAccessToken } from '~hooks/auth/useAccessToken'

export const useLogin = ({ onCompleted = () => null, ...rest }) => {
  const { setAccessToken } = useAccessToken()
  const { updateWebSockets } = useUpdateWebSockets()

  return useSharedLogin({
    ...rest,
    onCompleted: (props) => {
      onCompleted(props)
      updateWebSockets()
    },
    setAccessToken,
    url: `${process.env.NEXT_PUBLIC_API_AUTH_URL}/oauth/token`,
    clientId: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
  })
}
