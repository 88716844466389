import Link from 'next/link'
import {
  atlasmicRectangle,
} from '@atlasmic/icons'
import { useRouter } from 'next/router'
import { Box } from '~components/Box'
import { Meta } from '~components/Meta'
import { BaseLayout } from '~components/layouts/BaseLayout'
import { Form } from './Form'
import { signupHref } from './lib/signupHref'

export const Login = () => {
  const router = useRouter()

  return (
    <BaseLayout>
      <Meta
        title='Login to Atlasmic'
        description="Sign in to your Atlasmic account. Let's keep improving those numbers!"
      />
      <Box
        display={{ xs: 'flex', lg: 'grid' }}
        gridTemplateColumns='repeat(2, 1fr)'
        minHeight='100vh'
      >
        <Box
          backgroundColor='dark-primary'
          display={{ xs: 'none', lg: 'flex' }}
          alignItems='center'
          justifyContent='center'
          paddingX={2}
        >
          <Box
            component='img'
            src='/images/people-graph.png'
            maxWidth={54}
          />
        </Box>
        <Box
          backgroundColor='light-primary'
          display='flex'
          justifyContent='center'
          alignItems='center'
          paddingY={3}
          paddingX={2}
          flexGrow={1}
        >
          <Box>
            <Box
              borderBottom='separator'
              paddingBottom={4}
              marginBottom={4}
              display='flex'
              justifyContent='center'
            >
              <Box
                component='a'
                href={process.env.NEXT_PUBLIC_LANDING_URL}
                cursor='pointer'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Box
                  component={atlasmicRectangle}
                  fill='primary'
                />
              </Box>
            </Box>
            <Box>
              <Box
                component='h1'
                textStyle='h3'
                color='dark'
                textAlign='center'
                paddingBottom={1}
              >
                Welcome back to Atlasmic
              </Box>
              <Box
                textStyle='text'
                color='dark'
                textAlign='center'
              >
                New to Atlasmic?
                {' '}<Link href={signupHref({ router })}><a><Box cursor='pointer' display='inline' color='primary' textDecoration='underline' hoverColor='primary-darken5'>Sign up</Box></a></Link>
              </Box>
            </Box>

            <Form />
          </Box>
        </Box>
      </Box>
    </BaseLayout>
  )
}
