export const authRedirectUrl = ({
  router: {
    query: {
      redirect,
    },
  },
  fallbackUrl,
}) => (
  redirect || fallbackUrl
)
