import { forwardRef } from 'react'
import { Label } from '@atlasmic/shared/components/labels/Label'
import { Box } from '~components/Box'
import { MobileFriendlyInput } from '~components/inputs/MobileFriendlyInput'

export const MobileFriendlyInputWithLabel = forwardRef(function MobileFriendlyInputWithLabel({
  children,
  name,
  onChange,
  value,
  autoFocus,
  isError,
  ...rest
}, ref) {
  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <Label
        htmlFor={name}
      >
        {children}
      </Label>
      <MobileFriendlyInput
        ref={ref}
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        autoFocus={autoFocus}
        isError={isError}
        {...rest}
      />
    </Box>
  )
})
