export const signupHref = ({
  router: {
    query: {
      redirect,
    },
  },
}) => {
  if (!redirect) return '/signup'

  return `/signup?redirect=${encodeURIComponent(redirect)}`
}
