import { useRouter } from 'next/router'
import { authRedirectUrl } from './lib/authRedirectUrl'

export const useAuthRedirectUrl = ({
  fallbackUrl,
} = {
  fallbackUrl: '/',
}) => {
  const router = useRouter()

  return {
    authRedirectUrl: authRedirectUrl({ router, fallbackUrl }),
  }
}
