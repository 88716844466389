import { useState } from 'react'
import { useRouter } from 'next/router'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useLogin } from '~hooks/auth/useLogin'
import { MobileFriendlyInputWithLabel } from '~components/inputs/MobileFriendlyInputWithLabel'
import { MediumButton } from '@atlasmic/shared/components/buttons/MediumButton'
import { useLoginFormik } from '@atlasmic/shared/hooks/auth/useLoginFormik'
import { useAuthRedirectUrl } from '~hooks/auth/useAuthRedirectUrl'
import { useAccessToken } from '~hooks/auth/useAccessToken'
import { Box } from '~components/Box'
import { onError } from './lib/onError'

export const Form = () => {
  const accessTokenProps = useAccessToken()
  const { authRedirectUrl } = useAuthRedirectUrl()
  const { addToast } = useToast()
  const router = useRouter()

  const [isLoginCompleted, setIsLoginCompleted] = useState(false)
  const loginProps = useLogin({
    onCompleted: () => setIsLoginCompleted(true),
    onError: onError({ addToast }),
  })

  const { formik, loading, error } = useLoginFormik({
    loginProps,
    skip: !isLoginCompleted,
    onCompleted: () => router.push(authRedirectUrl),
    onError: onError({ addToast }),
  })

  return (
    <Box
      component='form'
      onSubmit={formik.handleSubmit}
      maxWidth={54}
    >
      <Box
        paddingY={3}
      >
        <Box>
          <MobileFriendlyInputWithLabel
            name='username'
            onChange={formik.handleChange}
            value={formik.values.username}
            isError={formik.errors.username && formik.submitCount > 0}
          >
            Email address
          </MobileFriendlyInputWithLabel>
        </Box>
        <Box
          paddingTop={2}
        >
          <MobileFriendlyInputWithLabel
            name='password'
            type='password'
            onChange={formik.handleChange}
            value={formik.values.password}
            isError={formik.errors.password && formik.submitCount > 0 || error}
          >
            <Box
              display='flex'
              flexGrow={1}
              justifyContent='space-between'
              alignItems='center'
            >
              <Box
                textStyle='text'
                color='dark'
              >
                Password
              </Box>
              <Box
                textStyle='text'
                color='dark-gray'
                onClick={() => atlasmic({ appType: 'CHAT', action: 'open' })}
                cursor='pointer'
                hoverTextDecoration='underline'
              >
                Forgot your password?
              </Box>
            </Box>
          </MobileFriendlyInputWithLabel>
        </Box>
      </Box>
      <Box
        display='flex'
        alignItems='center'
      >
        <MediumButton
          type='submit'
          width='100%'
          backgroundColor={loading ? 'dark-gray' : undefined}
          hoverBackgroundColor={loading ? undefined : 'primary-darken5'}
        >
          {loading ? 'Logging in...' : 'Log in'}
        </MediumButton>
      </Box>
    </Box>
  )
}
